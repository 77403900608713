import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styles from './Modal.module.scss'
import { ModalProps } from '~types'
import { disableBodyScroll } from 'utils'
import Image from 'next/image'
import cx from 'classnames'

const Modal = ({ isOpen, handleModalClose, children, closeOnOutsideClick = true, contentWidth }: ModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    disableBodyScroll(isOpen)
  }, [isOpen])

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event
      if (key === 'Escape' && isOpen && handleModalClose) {
        handleModalClose()
      }
    },
    [isOpen]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick) {
      handleModalClose()
    }
  }
  const classNames = cx([styles['modal-content'], styles[`modal-content--${contentWidth}`]])

  const modalContent = isOpen ? (
    <div className={styles.modal}>
      <div className={styles['modal-dialog']} role="document" onClick={onModalClick}>
        <div className={classNames}>
          <button className={styles['icon-close-container']} onClick={handleModalClose}>
            <Image src="/assets/icon-close.svg" alt="Close" width="0" height="0" sizes="100vw" priority />
          </button>
          {children}
        </div>
      </div>
    </div>
  ) : null

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById('root-modal'))
  } else {
    return null
  }
}

export default Modal
