import React from 'react'
import axios from 'axios'
import { type FileRecord } from '../ListView/ListView'
import { DownloadFileIcon } from '../Icons'

interface IDownloadFileProps {
  record: FileRecord
  closeDropdownMenu: Function
  displayError: Function
}

const DownloadSelectedFile = ({ record, closeDropdownMenu, displayError }: IDownloadFileProps) => {
  const createDownloadUrl = (recordTitle: string) => {
    //filePath construction will change if/when we add folders
    const filePath = `upload/${recordTitle}`
    const type = 'attachment'
    const presignedUrl = axios
      .get('/api/downloadfile', { params: { filePath, type } })
      .then(res => {
        return res.data.url
      })
      .catch(error => {
        console.error(error.message)
        throw error
      })

    return presignedUrl
  }

  const handleDownload: React.MouseEventHandler = async event => {
    const { target } = event
    const recordTitle = (target as HTMLButtonElement).value

    if (recordTitle) {
      try {
        const presignedUrl = await createDownloadUrl(recordTitle)
        if (presignedUrl) {
          const link = document.createElement('a')
          link.href = presignedUrl
          link.setAttribute('download', 'filename')
          link.target = '_blank'
          document.body.appendChild(link)
          link.click()
          closeDropdownMenu()
        }
      } catch (error) {
        console.error(error)
        displayError(`Failed to download file ${recordTitle}`)
        closeDropdownMenu()
      }
    } else {
      closeDropdownMenu()
    }
  }

  return (
    <button onClick={e => handleDownload(e)} value={record.title} id="download-button">
      <DownloadFileIcon />
      download
    </button>
  )
}

export default DownloadSelectedFile
