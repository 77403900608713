import styles from '../../modules/PreviewForm/index.module.scss'
import { IRememberMe } from '~interfaces'

const RememberMe = ({ forgotPassword, disabled, isChecked, onClick }: IRememberMe) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    const { key } = event
    if (key === ' ' || key === 'Spacebar') {
      onClick()
    }
  }

  return (
    <div className={styles['form-group']}>
      <div className={styles['checkbox']} id="remember-me-checkbox">
        <input type="checkbox" id="rememberMe" name="rememberMe" value="login" disabled={disabled} onClick={onClick} checked={isChecked} />
        <label className={styles['remember-me-label']} htmlFor="rememberMe" tabIndex={0} onKeyDown={handleKeyDown}>
          Remember me
        </label>
      </div>
      {forgotPassword ? (
        <a className={styles['remember-me-label']} style={{ marginLeft: 'auto' }} href={forgotPassword.link}>
          {forgotPassword.text}
        </a>
      ) : null}
    </div>
  )
}

export default RememberMe
