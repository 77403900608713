import React from 'react'
import { DeleteFileIcon } from '../Icons'

interface IDeleteFileProps {
  openDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
  closeDropdownMenu: Function
}

const DeleteSelectedFile = ({ openDeleteModal, closeDropdownMenu }: IDeleteFileProps) => {
  const handleDeleteButtonClick = () => {
    closeDropdownMenu()
    openDeleteModal(true)
  }

  return (
    <button onClick={() => handleDeleteButtonClick()} id="delete-button">
      <DeleteFileIcon />
      delete
    </button>
  )
}

export default DeleteSelectedFile
