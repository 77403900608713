import { ANALYTICS_LINK_MODULE_GLOBAL_NAV, ANALYTICS_LINK_LOCATION_FOOTER } from '~analyticsConstants'
import Image from 'next/image'
import { Carousel, Footer, Gallery, Header, Hero, HeroFullScreen, PreviewForm, Dashboard, RenderClient, VideoDetails } from '~modules'
import { Button, Form, Heading, Paragraph } from '~elements'
import {
  ButtonProps,
  FooterProps,
  FormProps,
  GalleryProps,
  HeaderProps,
  HeadingProps,
  HeroProps,
  ImageProps,
  ParagraphProps,
  VimeoPlaylist,
  Tags
} from '~types'
import { IVimeoGalleryVideo } from '@msgtechnology/sphere'
import { Parallax } from 'react-scroll-parallax'

const disableBodyScroll = (modalOpen: boolean) => {
  const html = document.querySelector('html')
  const body = document.querySelector('body')

  if (modalOpen) {
    html.style.overflowY = 'hidden'
    body.style.overflowY = 'hidden'
    // required for iOS
    html.style.touchAction = 'none'
    body.style.touchAction = 'none'
  } else {
    html.style.removeProperty('overflow-y')
    body.style.removeProperty('overflow-y')
    html.style.touchAction = 'auto'
    body.style.touchAction = 'auto'
  }
}

const objHasValue = (obj: object, key: string, value: string) => {
  return obj.hasOwnProperty(key) && obj[key] === value
}

const getFontSizeNumber = (fontSize: string | number): number => {
  if (typeof fontSize === 'number') return fontSize
  return Number(fontSize.split('_')[1])
}

const formatDate = (input: string) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const date = new Date(input)
  const monthName = months[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  return `${monthName} ${day}, ${year}`
}

const isTouchDevice = () => {
  if (typeof window !== 'undefined') return navigator.maxTouchPoints > 0
}

const getDynamicComponents = (
  blocks: Array<ButtonProps | FooterProps | FormProps | GalleryProps | HeaderProps | HeroProps | HeadingProps | ImageProps | ParagraphProps>,
  isChildOfModal: boolean = false
) => {
  return blocks.map((block: any) => {
    const componentName = block.componentName === 'ContentHeading' ? 'Heading' : block.componentName

    const renderComponent = () => {
      switch (componentName) {
        case 'Button':
          return (
            <Button
              key={block.id}
              {...block}
              // linkModule={ANALYTICS_LINK_MODULE_GLOBAL_NAV}
              // linkLocation={ANALYTICS_LINK_LOCATION_FOOTER}
            />
          )
        case 'Carousel':
          return (
            <Parallax key={block.id} speed={-10} opacity={[0, 3]}>
              <Carousel {...block} />
            </Parallax>
          )
        case 'Footer':
          return <Footer key={block.id} {...block} />
        case 'HeroFullScreen':
          return <HeroFullScreen key={block.id} {...block} />
        case 'Form':
          return <Form isChildOfModal={isChildOfModal} key={block.id} {...block} />
        case 'Gallery':
          return <Gallery key={block.id} {...block} />
        case 'Header':
          return (
            <Header key={block.id} {...block}>
              {block.title}
            </Header>
          )
        case 'Heading':
          return (
            <Heading key={block.id} {...block}>
              {block.title}
            </Heading>
          )
        case 'Hero':
          return <Hero key={block.id} {...block} />
        case 'Image':
          return <Image key={block.id} {...block} />
        case 'Paragraph':
          return <Paragraph key={block.id} {...block} />
        case 'PreviewForm':
          return <PreviewForm key={block.id} {...block} />
        case 'RenderClient':
          return <RenderClient />
        case 'Dashboard':
          return <Dashboard key={block.id} {...block} />
        case 'VideoDetails':
          return <VideoDetails key={block.id} {...block} />
        default:
          return null
      }
    }

    return renderComponent()
  })
}

const mapPlaylist = (vimeoData: VimeoPlaylist): Array<IVimeoGalleryVideo> => {
  const reduced: Array<IVimeoGalleryVideo> = vimeoData.reduce(function (result: Array<IVimeoGalleryVideo>, video) {
    if (!!video.play.progressive) {
      const hiDefVideo = video.play.progressive.find(video => video.rendition === '1080p')
      const loDefVideo = video.play.progressive.find(video => video.rendition === '240p')
      result.push({
        sId: video.resource_key,
        video: {
          url: hiDefVideo ? hiDefVideo.link : '',
          loDefVideo: loDefVideo ? loDefVideo.link : '',
          posterImage: video.pictures.base_link,
          title: video.name,
          uploadDate: video.created_time,
          tags: createRelationshipArray(video.tags)
        },
        thumbnail: video.pictures.base_link,
        videoDescription: video.description,
        autoplay: false,
        displayThumbnail: true,
        externalUrl: video.link
      })
    } else {
      //Videos that don't have a play.progressive value will be logged to the console
      console.log(video)
    }
    return result
  }, [])
  return reduced
}

const createRelationshipArray = (tagList: Tags[]) => {
  const relationshipArray: { category: string; name: string }[] = []
  tagList.forEach(item => {
    if (item.tag) {
      const splitString = item.tag.split('::')
      relationshipArray.push({
        category: splitString[0].toLowerCase(),
        name: splitString[1].toLowerCase()
      })
    }
  })
  return relationshipArray
}

function getInitialsByFNameLName(firstName: string, lastName: string) {
  const firstNameChar = firstName.charAt(0).toUpperCase()
  const lastNameChar = lastName.charAt(0).toUpperCase()
  return firstNameChar + lastNameChar
}

export * from './forms'
export * from './signaling'

export { disableBodyScroll, getDynamicComponents, objHasValue, getFontSizeNumber, isTouchDevice, mapPlaylist, formatDate, getInitialsByFNameLName }
