import { Heading, HelpIcon } from '~elements'
import { HeadingProps, ParagraphProps } from '~types'
import styles from './index.module.scss'
import Kicker, { IKicker } from '../../elements/Kicker'
import { ListView } from '~elements'
import React, { useEffect, useState } from 'react'
import UploadSelectedFile from 'components/elements/UploadFile'
import { Logout } from '~lib/aws/cognito/services'
import { PREVIEW_LOGIN_PATH } from '~constants'
import { getVideos } from 'services/getVideos'
import { useRouter } from 'next/router'

import cx from 'classnames'

interface IDashboard {
  heading: HeadingProps
  kicker: IKicker
  paragraph: ParagraphProps
}

export interface IVideos {
  experience_id: string
  version_number: string
  title: string
  id: string
  filesize: number
  uploaded_on: string
  firstname: string
  lastname: string
}

const Dashboard = ({ heading, kicker, paragraph }: IDashboard) => {
  const [videos, setVideos] = useState<IVideos[]>([])
  const [companies, setCompanies] = useState([])
  const [helpButtonClasses, setHelpButtonClasses] = useState(cx([styles['help-cta']]))
  const router = useRouter()

  useEffect(() => {
    ;(async () => {
      const { data } = await getVideos()
      setVideos([...data])
    })()
  }, [])

  useEffect(() => {
    const ninetyMinutes = 1000 * 60 * 90
    const timer = setTimeout(() => {
      Logout()
      router.replace(PREVIEW_LOGIN_PATH)
    }, ninetyMinutes)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (videos) {
      setCompanies([...new Set(videos.map(record => record.experience_id))])
    }
  }, [videos])

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-area']}>
        <div className={styles['dashboard-top-content']}>
          {heading && (
            <div className={styles['heading-container']}>
              <Heading {...heading} fontSize="32px" lineHeight="38px" />
              <a
                className={helpButtonClasses}
                target="_blank"
                href={`${process.env.NEXT_PUBLIC_CODA_HELP_URL}`}
                rel="noreferrer"
                // Really don't like this solution, but since we need to get rid of the hover state on click...we have this :).
                // More than open for another CSS or JS solution.
                onClick={() => setHelpButtonClasses(styles['help-cta'])}
                onMouseOver={() => setHelpButtonClasses(cx([styles['help-cta'], styles['help-cta-hover']]))}
                onMouseOut={() => setHelpButtonClasses(styles['help-cta'])}
              >
                <HelpIcon />
                <span>Help</span>
              </a>
            </div>
          )}
          {kicker && <Kicker {...kicker} color="#C6C6C6" fontSize="16px" lineHeight="24px" marginTop="4px" paddingLeft="8px" />}
          <UploadSelectedFile />
        </div>
        {companies.map(company => (
          <ListView key={company} companyName={company} records={videos.filter(record => record.experience_id === company)} />
        ))}
      </div>
    </div>
  )
}

export default Dashboard
