import React, { useState } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import styles from './Button.module.scss'
import { ButtonProps } from '~types'
import { Modal } from '~elements'
import { getDynamicComponents } from 'utils'
import { formatAnalyticsText, fireAnalytics, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_BUTTON_CLICK } from '~analyticsConstants'

const Button = ({
  color,
  disabled,
  fill,
  isChildOfModal,
  isFullWidth,
  isTargetBlank,
  justify,
  linkId,
  linkModule,
  linkLocation,
  modalBlocks,
  onClick,
  size,
  title,
  url,
  offset
}: ButtonProps) => {
  const classNames = cx([
    styles['btn'],
    styles[`btn--${size}`],
    styles[`btn--justify-${justify}`],
    styles[`btn--${color}`],
    styles[`btn--fill-${fill}`],
    styles[disabled || (!url && !onClick && !modalBlocks) ? `btn--is-disabled` : ''],
    styles[isFullWidth ? 'btn--is-full-width' : '']
  ])
  const { asPath } = useRouter()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const modalIsOpenHandler = (state: boolean) => {
    setModalIsOpen(state)
  }

  const analyticsLinkData = (title: string, linkModule: string, linkLocation: string, linkId?: string) => ({
    linkId: linkId ? linkId : formatAnalyticsText(title),
    linkModule: linkModule,
    linkLocation: linkLocation
  })

  const clickHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    title: string,
    linkModule: string,
    linkLocation: string,
    linkId?: string,
    offset?: number
  ) => {
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), analyticsLinkData(title, linkModule, linkLocation, linkId))
    if (onClick) onClick(e)

    const isAnchorLink = url && url.startsWith('#')
    if (isAnchorLink) {
      const targetElement = document.getElementById(url.substring(1))
      if (targetElement) {
        const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({
          top: offset ? targetPosition - offset : targetPosition,
          behavior: 'smooth'
        })
      }
    } else if (url) {
      if (isTargetBlank) {
        window.open(url, '_blank')
      } else {
        window.location.assign(url)
      }
    }
  }

  return (
    <>
      {(modalBlocks && modalBlocks.length) || onClick || url ? (
        <>
          <button
            className={classNames}
            disabled={disabled}
            onClick={() => {
              clickHandler(React.MouseEvent<HTMLButtonElement>, title, linkModule, linkLocation, linkId, offset)
              modalBlocks && modalBlocks.length && !isChildOfModal ? modalIsOpenHandler(true) : null
            }}
          >
            {title}
          </button>

          {modalBlocks && modalBlocks.length && !isChildOfModal ? (
            <Modal isOpen={modalIsOpen} handleModalClose={() => modalIsOpenHandler(false)} closeOnOutsideClick={false}>
              {getDynamicComponents(modalBlocks, true)}
            </Modal>
          ) : null}
        </>
      ) : null}
    </>
  )
}

Button.defaultProps = {
  color: 'primary',
  disabled: false,
  fill: 'solid',
  isChildOfModal: false,
  isFullWidth: false,
  isTargetBlank: false,
  justify: 'left',
  size: 'md'
}

export default Button
